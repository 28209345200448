import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Negativzins = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Negativzins – Why so negative?" showCalc={false} />
            <Article>
                <p>
                    Wenn jemand Geld verleiht, dann kann er es jetzt nicht mehr ausgeben.{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    k&ouml;nnen grunds&auml;tzlich verstanden werden als der Preis f&uuml;r diese Leihe, den der
                    Schuldner an den Gl&auml;ubiger f&uuml;r den direkten Konsumverzicht zahlt - nat&uuml;rlich nebst
                    des Risikos, das Geld nie wiederzusehen. Seit einigen Jahren wird nun im Zusammenhang mit der
                    EZB-Leitzins Politik immer wieder &uuml;ber Negativzinsen gesprochen. Eine auf den ersten Blick
                    komische Idee: Denn wenn der Zins &bdquo;negativ&ldquo; ist, dann bezahlt der Gl&auml;ubiger den
                    Schuldner daf&uuml;r, sein Geld zu nehmen &ndash; dabei bekommt er doch weniger zur&uuml;ck als
                    vorher, wieso dann &uuml;berhaupt verleihen. Warum das so ist, der Negativzins trotzdem als ein
                    sinnvolles Instrument der Zentralbanken gehandhabt wird und worauf man als Verbraucher in Zeiten des
                    negativen Leitzinses alles achten muss &ndash; mit diesen Fragen besch&auml;ftigt sich der folgende
                    miracl Artikel.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was sind Negativzinsen?</h2>
                <p>
                    Seit Mitte des Jahres 2014 ist der Leitzins oder Einlagezins der Europ&auml;ischen Zentralbank
                    negativ, der heutige Stand sind -0,5% (&hellip;). F&uuml;r die Kunden der EZB &ndash;die
                    Gesch&auml;ftsbanken &ndash; bedeutet das: Falls sie Geld besonders sicher bei der EZB hinterlegen
                    wollen, m&uuml;ssen sie daf&uuml;r zahlen. Diese drohenden Kosten haben Auswirkungen auf das
                    Tagesgesch&auml;ft der Gesch&auml;ftsbanken mit ihren Kunden, den Verbrauchern. Daher ist es als
                    Verbraucher wichtig, den Einfluss des Negativzinses, also des negativen Leitzinses der EZB auf
                    Finanzangebote der Gesch&auml;ftsbanken wie Einlagen und{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredite
                    </Link>{" "}
                    zu kennen. Denn unn&ouml;tige Ausgaben, die auftreten, wenn die Gesch&auml;ftsbanken die
                    Negativzinsen auf die Verbraucher abw&auml;lzen, lassen sich gut und gerne vermeiden, daf&uuml;r
                    aber auch entstandene Vorteile gut und gerne nutzen.
                </p>
                <hr />

                <h2>Effekte des Negativzinses f&uuml;r Verbraucher - bei Krediten</h2>
                <p>
                    F&uuml;r Verbraucher bergen Negativzinsen n&auml;mlich nicht nur Risiken und potentielle Kosten.
                    Denn f&uuml;r Kreditnehmer gilt: Fallen die Einlagenzinsen, fallen auch die Kreditzinsen.{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredite
                    </Link>{" "}
                    sind wegen des Negativzinses momentan also zu sehr g&uuml;nstigen Konditionen abzuschlie&szlig;en.
                </p>
                <p>
                    Denn Banken bestimmen die H&ouml;he des Kreditzinssatzes &uuml;ber den Referenzzinssatz &ndash; in
                    &Ouml;sterreich ist das der EURIBOR, welcher sich am Leitzins orientiert und dem jeweiligen
                    Aufschlag der Bank, der Zinsmarge. Der EURIBOR ist aber, da er sich am Leitzins der EZB orientiert,
                    seit einigen Jahren negativ. Bedeutet das jetzt Negativzinsen f&uuml;r Kreditnehmer? Nein, nicht
                    direkt. 2017 wurde diese Frage in &Ouml;sterreich vor dem OGH behandelt mit der Entscheidung, dass
                    erstens der negative Referenzzinssatz g&auml;nzlich weiterzugeben ist, aber zweitens der effektive
                    Kreditzinssatz nicht unter null fallen darf. Es gibt also keine Negativzinsen f&uuml;r Kreditnehmer
                    in dem Sinne, dass es freies Geld f&uuml;r den Kreditabschluss gibt, aber die Kreditzinsen fallen
                    oft geringer aus als allein der &uuml;bliche Aufschlag der Bank &ndash; ein historisches Tief und
                    damit beste Voraussetzungen f&uuml;r einen Kreditabschluss.
                </p>
                <hr />

                <h2>Effekte des Negativzinses f&uuml;r Verbraucher - bei Einlagen</h2>
                <p>
                    Im Gegensatz zu Kreditnehmern k&ouml;nnen sich Negativzinsen daf&uuml;r ung&uuml;nstig auf Einleger
                    auswirken. Denn Banken sind nat&uuml;rlich versucht, die von der EZB angedrohten Kosten f&uuml;r ihr
                    eigenes Einlegen einfach an ihre Kunden weiterzureichen. In Deutschland zum Beispiel ist dies
                    inzwischen &uuml;blich: Mit heutigem Stand erheben 429 Gesch&auml;ftsbanken dort Negativzinsen
                    f&uuml;r Privatkunden. Meist ab einem Betrag von 100.000 Euro werden um die -0,5% Negativzins
                    f&auml;llig. &nbsp;
                </p>
                <p>
                    In &Ouml;sterreich gibt es, &auml;hnlich wie f&uuml;r Kreditzinsen, ein rechtliches Verbot f&uuml;r
                    Einlagezinsen unter null, welches 2009 vom OGH verabschiedet wurde. Dieses Verbot f&uuml;r
                    Negativzinsen gilt aber ausschlie&szlig;lich f&uuml;r Sparkonten! Zwar ist es &uuml;blich, dass auch
                    auf andere Privatkonten keine Negativzinsen erhoben werden, dennoch sind Girokonten aber auch kleine
                    und mittelst&auml;ndische Unternehmer mit ihren Konten nicht von dieser Regelung gesch&uuml;tzt.
                </p>
                <p>
                    Als einlegender Verbraucher in &Ouml;sterreich gilt es also darauf zu achten, dass ein etwaiger
                    Negativzins einem von den Gesch&auml;ftsbanken in Zukunft nicht als steigende Geb&uuml;hr oder
                    Verwahrentgelt untergeschoben wird. Sonst kann man sich vor Negativzinsen soweit recht gefeit
                    wissen.
                </p>
                <hr />

                <h2>Warum kommt es &uuml;berhaupt zum Negativzins?</h2>
                <p>
                    Vor 2014 gab es bisher einen historischen Fall von Negativzinsen. Die Schweiz sah sich in den 1970er
                    Jahren mit dem Problem konfrontiert, dass ihre Finanzinstitute voll von ausl&auml;ndischem Kapital
                    waren. Die Folge war, dass der Schweizer Franken so sehr aufgewertet wurde, dass
                    Exporteinbu&szlig;en verzeichnet wurden. Um die Schweizer Wirtschaft zu sch&uuml;tzen f&uuml;hrte
                    man Negativzinsen ein. Dies sollte den Franken entwerten, was aber nur m&auml;&szlig;igen Erfolg
                    aufwies.
                </p>
                <p>
                    Heute verfolgt die EZB mit ihrer Negativzinspolitik andere Zwecke. Es geht ihr haupts&auml;chlich
                    darum, die Wirtschaft nach den langfristigen Auswirkungen der Finanzkrise 2008 anzukurbeln. Sind
                    n&auml;mlich, wie wir gesehen haben, die Einlagezinsen niedrig, sind auch die Kreditzinsen niedrig.
                    Nicht nur f&uuml;r Verbraucher, sondern auch f&uuml;r Unternehmen stellt dies einen Anreiz dar,{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredite
                    </Link>{" "}
                    zu g&uuml;nstigen Konditionen f&uuml;r Investitionsprojekte aufzunehmen, was dann das
                    Wirtschaftswachstum f&ouml;rdern soll.
                </p>
                <hr />

                <h2>Fazit &ndash; alles nicht so negativ!</h2>
                <p>
                    Dass immer mehr Gesch&auml;ftsbanken den Negativzins f&uuml;r Einlagen an ihre Kunden weitergeben,
                    und dabei das Kreditvolumen trotz bester Konditionen nur bedingt w&auml;chst, ist dabei eine
                    m&ouml;gliche Kritik an der Negativzinspolitik der EZB. F&uuml;r den &ouml;sterreichischen
                    Verbraucher steht aber derzeit fest: Negativzinsen erm&ouml;glichen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredite
                    </Link>{" "}
                    zu historisch einzigartig g&uuml;nstigen Konditionen und das &ouml;sterreichische Gesetz sowie der
                    Habitus am &ouml;sterreichischen Finanzmarkt sch&uuml;tzen ihn vor allzu gro&szlig;en negativen
                    Effekten beim Einlegen. Er kann also langfristig gut davon profitieren.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"negativzins"}></BreadcrumbList>
            <ArticleStructuredData page={"negativzins"} heading={"Negativzins – Why so negative?"} />
        </Layout>
    );
};

export default Negativzins;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.negativzins", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
